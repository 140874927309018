import ui from "../objects/uiProps.js";
import { startDrawing, updateDrawing, stopDrawing } from '../drawing.js';
import textNode from "../textNodeDraw.js";
import {allDrag, allUndrag} from '../drag.js';
import shape from '../objects/shapes.js';


///////////////left bar tools
export default ui.buttons.forEach(el => {
  el.addEventListener('click', () => {
    if(el.id === 'in-grid'){
      let bgImg = new URL(`../../images/grid-bg-598.jpg`, import.meta.url);
      console.log(bgImg.href);
      el.classList.toggle("active_btn")
      ? (Snap("#stg").node.style.backgroundImage = `url('${bgImg.href}')`)    //"url('img_tree.png')"
      : (Snap("#stg").node.style.backgroundImage = "");
      return;
    }
    //remove then add active class
    highliteActiveButton(el);
    //remove existing events on stage
    Snap("#stg").unmousedown(startDrawing).unmousedown(textNode);
    //remove drag from all el's
    allUndrag();
    //set object value
    shape.type = el.id;
    //add relevant event to stage
    if(shape.drawTools.includes(el.id)){
      Snap("#stg").node.style.cursor = "crosshair";
      Snap("#stg").mousedown(startDrawing, shape.type);
    }else if(el.id === 'in-txt'){
      console.log(el.id);
      Snap("#stg").node.style.cursor = "text";
      Snap("#stg").mousedown(textNode);
    }else if(el.id === 'in-move'){
      Snap("#stg").node.style.cursor = "default";
      targetEl.el = targetEl.node = null;
    }
    //add drag back to all el's
    allDrag();
  });
});



//sidebar - remove class from active button
const highliteActiveButton = (el) => {
  //remove all active classes
  ui.buttons.forEach( el => {
    if(el.classList.contains("active_btn")) el.classList.remove("active_btn");
  });
  //add active class to active button
  el.classList.add("active_btn");
};



