//import SVGImage from "./objects/stage";
import ftAdd from '../modules/ftAdd.js';

/*center the stage when document loaded into window*/
export const stageCenter = () => {
    Snap("#stg").circle(45000, 45000, 2).attr({fill:"#000000", "fill-opacity":0, id:"cntr"});
    document.getElementById("cntr").scrollIntoView({ behavior: "instant", block: "center", inline: "center" });
    const stage = Snap("#stg");
    const el = stage.line(44271, 0, 44271, 90000).attr({fill:"#999999", stroke:"#999999", "stroke-width":"1px", "id": "rulerStage"});
      ftAdd(el);
      if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
}
