
//this is the clicked element on the stage
  //export const trgt = {
    export default {
      el: null,
      ft: "",
      node: null, //node
      foNode: null, //foreighObject node
      name: "",
      mceClass: null, //timymce unique class name <div id="txt_bx" class="txt_ed_w els83982437"> trgt.node.classList[1]
      mceStatus: false,
      mceObj: null, //reference to tinymce editor obj
      groupedEl: null, //grouped element
      handle: null,
      getAttributes: null, //for getting arrow status
      SVGUploadFlag: 0,
      mathConst: "",
      scrollPastepre:{x:0, y:0}
    }
   