import shape from "./objects/shapes.js";
//import Snap("#stg") from "./objects/stage";
import ftAdd from './ftAdd.js';
import targetEl  from "./objects/targetEl.js";



  export default function (e) {
    //use URL() for parcel import.meta.url
    let fo = new URL(`../foreignobj.svg`, import.meta.url);
    Snap.load(fo, function (frag) {
      Snap("#stg").append(frag);
      //co-ord for text box https://stackoverflow.com/questions/3234256/find-mouse-position-relative-to-element
      var rect = Snap("#stg").node.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.

      //select newly created el and give it a new id
      frag = Snap("#stg").select("#fo_if1234");
      
      frag.node.children[0].classList.add(
        "els" + Math.floor(Math.random() * 100000000)
      );
      frag.attr({ x: x, y: y });
      frag.node.removeAttribute("id");
      ftAdd(frag);
 
      //console.log(frag.node.firstChild);
      //console.log(frag.node.firstChild.classList[1]);

      Snap("#stg").node.style.cursor = "default";
      //targetEl.mceClass = frag.node.childNodes[1].classList[1];  //use this for npm start
      targetEl.mceClass = frag.node.firstChild.classList[1]; //use this for npm build
      

      frag.data("new", 1);
      //frag.data("textActive", 1);

      //add outline
      frag.node.classList.add("txt_outlined");
      frag.data("txtOutline", 1);
      Snap("#stg").unmousedown();
    });
  };