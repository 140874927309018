import { modalStatus} from '../modules/modal.js';
import ftAdd from './ftAdd.js';

//DRAG DROP UPLOAD
document.getElementById("dz").addEventListener("dragover", function (e) {
  document.querySelector(".box").style.outline = "2px dashed rgb(178, 222, 39)";
  e.preventDefault();
});

document.getElementById("dz").addEventListener("dragleave", function (e) {
  document.querySelector(".box").style.outline = "2px dashed #92b0b3";
});

document.getElementById("dz").addEventListener("drop", function (e) {
  document.querySelector(".box").style.background = "rgba(34, 47, 62,0.9)";
  e.preventDefault();
  modalStatus('modalImportSVG', 'none', 'none');
  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < e.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (e.dataTransfer.items[i].kind === "file") {
        const reader = new FileReader();
        var file = e.dataTransfer.items[i].getAsFile();
        if (file.size > 4194304) {
          alert("File is too big! Max file size allowed is 4MB");
          return;
        }
        // convert image file to base64 string
        reader.addEventListener("load", function () {
            //get image dimensions and add to stage
            const image = new Image();
            image.src = reader.result;
            image.onload = function () {
              let bf = document.querySelector("foreignObject"); //bf = before
              var el;
              if (bf != null) {
                el = Snap("#stg").image(
                  reader.result,
                  window.scrollX + (window.innerWidth / 2 - image.width / 2),
                  window.scrollY + (window.innerHeight / 2 - image.height / 2),
                  image.width,
                  image.height
                )
                  .attr({ class: "els" }).insertBefore(bf);
              } else {
                el = Snap("#stg").image(
                  reader.result,
                  window.scrollX + (window.innerWidth / 2 - image.width / 2),
                  window.scrollY +
                    (window.innerHeight / 2 - image.height / 2),
                  image.width,
                  image.height
                ).attr({ class: "els" });
              }
              ftAdd(el);
            };
          },
          false
        );

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (var i = 0; i < e.dataTransfer.files.length; i++) {
      //console.log('... file[' + i + '].name = ' + e.dataTransfer.files[i].name);
    }
  }
});



//UPLOAD BUTTON
document.getElementById("upl").addEventListener("change", function (e) {
  const file = document.getElementById("upl").files[0];
  const reader = new FileReader();

  if (file.size > 4194304) {
    alert("File is too big! Max file size allowed is 4MB");
    return;
  }

  reader.addEventListener("load",function () {
      //get image dimensions and add to stage
      const image = new Image();
      image.src = reader.result;
      image.onload = function () {
        //console.log(image.height);
        //console.log(image.width);
        //NB NB NB NB still do - get center of stage to paste image
        //let el = SVGImage.image(reader.result, 5637, 5202, image.width, image.height) //Paper.image(src, x, y, width, height)

        let bf = document.querySelector("foreignObject") || null; //bf = before
        var el;
        //drawObj = shape.getDrawObject(shape.type,x,y);
        if (bf != null) {
          el = Snap("#stg").image(
            reader.result,
            window.scrollX + (window.innerWidth / 2 - image.width / 2),
            window.scrollY + (window.innerHeight / 2 - image.height / 2),
            image.width,
            image.height
          )
            .attr({ class: "els" })
            .insertBefore(bf);
        } else {
          el = Snap("#stg").image(
            reader.result,
            window.scrollX + (window.innerWidth / 2 - image.width / 2),
            window.scrollY + (window.innerHeight / 2 - image.height / 2),
            image.width,
            image.height
          ).attr({ class: "els" });
        }
        ftAdd(el);
      };
    },
    false
  );

  if (file) {
    reader.readAsDataURL(file);
  }
});

/***********************************/