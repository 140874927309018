//import SVGImage from "./objects/stage";
import targetEl  from "./objects/targetEl.js";
import ui from "./objects/uiProps.js";  
import { activeRemove } from "./activeRemove.js";
import borderProperties from "./borderProperties.js";
import shape from "./objects/shapes.js";
import configTinymce from "./configTinymce.js";
  /*add all mouse events to el - this function is called when the el is created - only once per el
   *- when el's are drawn
   *- imported to stage for images and text
   *************************************** */

   export default function (el) {

    //add freetransform
    if (el.node.nodeName === "image") {
      Snap("#stg").freeTransform(el, {
        size: 3,
        rotate: true,
        keepRatio: true,
        attrs: { fill: "#ffffff", stroke: "#000000" }
      });
    } else if (el.node.id === "ruler"){
        Snap("#stg").freeTransform(el, {
          size: 3,
          rotate: false,
          attrs: { fill: "#ffffff", stroke: "#000000" },
          range: { rotate: [ 0, 0 ], scale: [ 0, 0 ] }
        });
      }else{
        Snap("#stg").freeTransform(el, {
        size: 3,
        rotate: true,
        attrs: { fill: "#ffffff", stroke: "#000000" },
      });
      }





    //when first created:
    //tinymce init
    if (el.node.classList[1] == "txt") {
      if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
      el.data("ftStatus", 0);
      targetEl.el = el; //foreignObject.els.txt
      targetEl.node = el.node.children[0]; //<div id="txt_bx" class="txt_ed"> this is used for tinymce
      targetEl.foNode = targetEl.el.node; //<foreignObject class="els txt"
      targetEl.mceClass = targetEl.node.classList[1];
      //tinymce.init(configTinymce());
    } else {
      targetEl.mathConst = el; //set here for first draw
      //show handles for shapes
      if(el.freeTransform) el.freeTransform.showHandles();
      el.data("ftStatus", 1);
      //ft.hideHandles({undrag: true});
      Snap("#stg").node.style.cursor = "default";
    }

    //get clicked el and remove outilne and any active handles
    el.click(function (e) {
      console.log(el);
      /*this property is for matrix math. it is set only once on click of element.
       so each time a new el is selected it is updated and stored until another el is clciked.*/
      targetEl.mathConst = el; //set here for first draw
      activeRemove(e); //removes outline on other el's
      
      e.stopPropagation(); //stop bubbling to stage
      targetEl.el = el;
      targetEl.node = el.node;
      targetEl.name = e.target.nodeName;
      //hide/show border properties
      borderProperties(targetEl.el);
      //set style drop down box to correct value for el
      //setStyle(targetEl.el);

      //if in-text is active i.e creating a new text node
      if(el.data("textActive") === 1){
        if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
        el.node.classList.add("txt_outlined"); //add outline
        el.data("txtOutline", 1);
      }else{
        //all elements except text
      if (targetEl.node.classList[1] != "txt") {
        if (el.data("ftStatus")) {
          if(el.id !== targetEl.el.id){
            if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
            el.data("ftStatus", 0);
          }
          
        } else {
          if(el.freeTransform) el.freeTransform.showHandles();
          el.data("ftStatus", 1);
          //if(el.node.nodeName === 'image') el.freeTransform.opts.keepRatio = [ 'axisX', 'axisY', 'bboxCorners', 'bboxSides' ];
        }
        //set ui properties for clicked element
        el.data("startM")
          ? ui.startM.classList.add("prop-sel")
          : ui.startM.classList.remove("prop-sel");
        el.data("endM")
          ? ui.endM.classList.add("prop-sel")
          : ui.endM.classList.remove("prop-sel");

        if (el.data("lineStyle") === "solid") {
          ui.line[0].classList.add("prop-sel");
          ui.line[1].classList.remove("prop-sel");
        } else {
          ui.line[1].classList.add("prop-sel");
          ui.line[0].classList.remove("prop-sel");
        }
      } else {
        //textoutline is added to text foreignobject only
        el.node.classList.add("txt_outlined"); //add outline
        el.data("txtOutline", 1);
      }
      }  
    });

    //double click is for text el only
    el.dblclick(function (e) {
      //e.stopPropagation(); //stop bubbling to stage
      targetEl.node = el.node;
      targetEl.name = e.target.nodeName;
      //if txt then add tinymce init event
      if (targetEl.node.classList[1] === "txt") {
        /*
        fr freetransform is the 3rd mousedown event attached to the element.
        hideHandles() removes the mousedown event
        undrag: true removes the drag  --adds back in blur
        */
        el.data("ftStatus", 0);
        el.attr({ cursor: "auto" });
        el.freeTransform.hideHandles({ undrag: true });
        //set tinymce config values
        targetEl.el = el; //foreignObject.els.txt
        targetEl.node = el.node.children[0]; //<div id="txt_bx" class="txt_ed"> this is used for tinymce
        targetEl.foNode = targetEl.el.node; //<foreignObject class="els txt"
        targetEl.mceClass = targetEl.node.classList[1];
        //attach tinymce
        tinymce.init(configTinymce()); //from config selector: '.txt_ed/ed....',
      }
    });

    //hide handles when svg uploaded
    if (targetEl.SVGUploadFlag == 1) {
      if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
      targetEl.SVGUploadFlag = 0;
    }
  };
  /**************************** */