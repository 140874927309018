/************************************
 * General Notes
 * When an HTML document is loaded into a web browser, it becomes the document object.
 * The document object (HTMLDocument) is a property of the window object.
 * 
 * Snap("#stg")  
 * Snap("#stg") - First lets create our drawing surface out of existing SVG element
 * click and mousedown events for drawing are attached to the drawing surface Snap("#stg")
 * 
 * Element Object
 * elemet objects are added to document object using functions:
 * - startDrawing   ->   updateDrawing    ->   stopDrawing
 * these are called using the click, dblclick and mousedown events which are attached to the stage
 * In the HTML DOM, the Element object represents an HTML element, like P, DIV, A, TABLE, or any other HTML element.
 * 
 * 
 * freeTransform plugin
 * freeTransform property is added to each element object
 * this library adds drag, resize and rotate to the elements
 * each el has 4 events attached - 1.click, 2.dblclick, 3.mousedown, 4.another mousedown event for freeTransform 
 * 
 */

'use strict';

import "../modules/stageClear.js";
import "../modules/context_menu.js";
import '../modules/modal.js';
import '../modules/ui/barLeft.js';
import "../modules/objects/uiProps.js";
import '../modules/activeRemove.js';
import "../modules/objects/targetEl.js";
import "../modules/objects/shapes.js";
import "../modules/objects/elStyles.js";
import '../modules/drawing.js';
import '../modules/zoom.js';

// Import TinyMCE
import './tinymce/tinymce.js';
import './tinymce/themes/silver/theme.js';
import './tinymce/icons/icons/icons.js';
import './tinymce/skins/ui/oxide-dark/skin.min.css';
import './tinymce/skins/ui/oxide-dark/content.inline.min.css';
import './tinymce/plugins/link/plugin.js';
import './tinymce/plugins/lists/plugin.js';
import './tinymce/plugins/autolink/plugin.js';
import './tinymce/plugins/advlist/plugin.js';
import './tinymce/plugins/table/plugin.js';
import './tinymce/plugins/autoresize/plugin.js';
import './tinymce/plugins/paste/plugin.js';
import './tinymce/plugins/charmap/plugin.js';
import './tinymce/plugins/contextmenu/plugin.js';

import '../modules/clone.js';
import '../modules/ui/barBottom.js';
import '../modules/deleteNode.js';   
import '../modules/pageReload.js';
import '../modules/clipboard.js';
import '../modules/fullScreen.js';
//import '../modules/download';
import '../modules/uploadImage.js'
import '../modules/importSVG.js';
import { stageCenter } from '../modules/stageCenter.js';


stageCenter();
 

 





//redirect if not domain
//if(window.location.href != 'https://www.waenote.com/' && window.location.href != 'https://waenote.com/') window.location.href = 'https://www.waenote.com/forbidden.html';  




/* download SVG *******************/
document.getElementById("dwnld").addEventListener("click", function () {
  //saveModal.style.display = "block";
  const SVGImage = Snap("#stg");
  const serializer = new XMLSerializer();
  if(document.querySelector(".ftaxisline")) SVGImage.node.lastChild.remove();
  
//if any active text blocks then remove
  if(document.querySelector(".act")){
    for (const el of els) {
      el.className === 'act' ? el.classList.remove('act', 'txt_outlined') : null;
    }
  }

  //remove rulerStage
  document.getElementById("rulerStage")?.remove() || null;
  
  const str = serializer.serializeToString(SVGImage.node);
  //console.log(str);

  svgExport.downloadSvg(
    str, // SVG DOM Element object to be exported. Alternatively, a string of the serialized SVG can be passed
    "skratche", // chart title: file name of exported image
    { useCSS: "false" } // defualt is true whish looks in external css stylesheet
  );
});