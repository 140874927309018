//import SVGImage from "../modules/objects/stage";


/* clear stage */
export function clearSVG () {
  if(this !== window){
    console.log('delete button');
    if(confirm("Confirm clear all content?")) Snap("#stg").clear(); 
  }else{
    Snap("#stg").clear();
    Snap("#stg").remove();
  }
  
  /*Snap("#stg").circle(45000, 45000, 1).attr({
    fill: "#000",
    id: "cnt",
    style: "pointer-events: none"
  });*/

}

document.getElementById("clear").addEventListener("click", clearSVG);