//import SVGImage from "./objects/stage";
import targetEl  from "./objects/targetEl.js";
import elStyle from "./objects/elStyles.js";
import shape from './objects/shapes.js';
import ftAdd from './ftAdd.js';
import {allDrag, allUndrag} from './drag.js';
import ui from "./objects/uiProps.js";

let drawObj = null;
let bufferSize = 20;
let path = null;
let strPath;
let buffer = []; // Contains the last positions of the mouse cursor

 
 
 
 ///////////////////drawing shapes
 export const getDrawObject =  function (shape, x, y) {
  let svg = null;
  switch (shape) {
    case "in-rect":
      svg = Snap("#stg").rect(x, y, 0, 0);
      break;
    case "in-line":
      svg = Snap("#stg").line(x, y, x, y);
      break;
    case "in-ellipse":
      svg = Snap("#stg").ellipse(x, y, 0, 0);
      break;
    case "in-poly":
      svg =  Snap("#stg").polyline(x, y).attr({class: "els"});
      break;
    case "in-line-curve-l":
    case "in-line-curve-r":
      svg =  Snap("#stg").path();
      break;
      default: //curley brackets in-curl
      svg =  Snap("#stg").path().attr({class: "els"});
  }
  return svg.attr({
    stroke: "#222F3E",
    fill: "transparent",
    "stroke-width": elStyle.lineWidth,
    "vector-effect": "non-scaling-stroke",
    "stroke-linecap": "round"
  });
};

export const startDrawing = function (event, x, y) {
  //cannot use 'this' instead of shape -  because it is called in a  event so the 'this' defaults to the element
  shape.sPos = { x1: x, y1: y }; //save start positions into obj for use in update drawing
  if (
    shape.type === "in-ellipse" || shape.type === "in-rect" || shape.type === "in-circle"
  ) {
    let bf = document.querySelector("foreignObject") || null; //bf = before
    if (bf != null) {
      drawObj = getDrawObject(shape.type, x, y).insertBefore(bf);
    } else {
      drawObj = getDrawObject(shape.type, x, y);
    }
    targetEl.name = drawObj.type;
    targetEl.node = drawObj.node;
  } else {
    drawObj = getDrawObject(shape.type, x, y);
    targetEl.name = drawObj.type;
    targetEl.node = drawObj.node;
  }
  if (shape.type === "in-poly") {
    shape.points = drawObj.attr("points");
    console.log(shape.points);   //Array [ "44533", "45039" ]
     buffer = [];
     var pt = {x:shape.points[0]*1, y:shape.points[1]*1};
     strPath = pt.x + " " + pt.y + " ";
     //console.log(pt);
     appendToBuffer(pt);
  } else shape.points = drawObj.attr();
  //remove drag events
  allUndrag();
  //when start drwaing add the update and stopdrawing  events
  Snap("#stg").mousemove(updateDrawing).mouseup(stopDrawing);   //this adds the mouseup event
}

export const updateDrawing = function (event, x, y) {
  /*x1,y1 is starting static mouse position
  x,y is current (dynamic) mouse position*/
  const x1 = shape.sPos.x1,
        y1 = shape.sPos.y1;

  switch (shape.type) {
    case "in-rect":
      drawObj.attr({
        x: Math.min(x1, x), //min() takes lowest value
        y: Math.min(y1, y),
        width: Math.abs(x1 - x),
        height: Math.abs(y1 - y),
      });
    break;
    case "in-line":
      drawObj.attr({ x2: x, y2: y });
    break;
    case "in-circle":
      //from the mousedown x and y subtract the current mouse position and calculate radius based on greater of x or y
      let a = Math.abs(x1 - x);
      let b = Math.abs(y1 - y);
      if (a > b) drawObj.attr({ r: a });
      else drawObj.attr({ r: b });
    break;
    case "in-ellipse":
        shape.points.rx = Math.abs(x1 - x);
        shape.points.ry = Math.abs(y1 - y);
      drawObj.attr({ rx: shape.points.rx, ry:  shape.points.ry });
    break;
    case "in-poly":
      appendToBuffer({ x, y });
      updateSvgPath();
    break;
    case "in-line-curve-l":
    case "in-line-curve-r":
      let initialX = x1; // Assuming x1 is your initial x value
let initialY = y1; // Assuming y1 is your initial y value

// Calculate the change in x and y
let deltaX = Math.abs(x - initialX);
let deltaY = Math.abs(y - initialY);

// Calculate the total number of increments for every 10-point change
let incrementsX = Math.floor(deltaX / 10);
let incrementsY = Math.floor(deltaY / 10);

// Update the offset based on the number of increments
let offset = 30 + (incrementsX + incrementsY) * 2;

// Determine which increment is greater and replace the lesser one
if (incrementsX > incrementsY) {
    // Replace y with its starting point y1
    y = y1;
} else if (incrementsY > incrementsX) {
    // Replace x with its starting point x1
    x = x1;
}

// Calculate midpoints
let mpx = (x + x1) * 0.5,
    mpy = (y + y1) * 0.5,
    theta;

// Angle of perpendicular to line:
theta = Math.atan2(y - y1, x - x1) - Math.PI / 2;

// Location of control point:
let c1x = mpx + offset * Math.cos(theta);
let c1y = mpy + offset * Math.sin(theta);

drawObj.attr({ d: "M" + x1 + " " + y1 + " Q " + c1x + " " + c1y + " " + x + " " + y });
    break;
    default:
      //curly bracket
        const w = 50,
        q = 0.6; 
        //Calculate unit vector
        let dx = x1-x;
        let dy = y1-y;
        let len = Math.sqrt(dx*dx + dy*dy);
        dx = dx / len;
        dy = dy / len;

        //Calculate Control Points of path,
        let qx1 = x1 + q*w*dy;
        let qy1 = y1 - q*w*dx;
        let qx2 = (x1 - .25*len*dx) + (1-q)*w*dy;
        let qy2 = (y1 - .25*len*dy) - (1-q)*w*dx;
        let tx1 = (x1 -  .5*len*dx) + w*dy;
        let ty1 = (y1 -  .5*len*dy) - w*dx;
        let qx3 = x + q*w*dy;
        let qy3 = y - q*w*dx;
        let qx4 = (x1 - .75*len*dx) + (1-q)*w*dy;
        let qy4 = (y1 - .75*len*dy) - (1-q)*w*dx;

        drawObj.attr({d:  "M " +  x1 + " " +  y1 + " Q " + qx1 + " " + qy1 + " " + qx2 + " " + qy2 + " T " + tx1 + " " + ty1 + " M " +  x + " " +  y + " Q " + qx3 + " " + qy3 + " " + qx4 + " " + qy4 + " T " + tx1 + " " + ty1 });
  }
}

export const stopDrawing = function (event, x, y) {
  //this is just removing the mouseup and mousemove startdrawing events
  Snap("#stg").unmousemove(updateDrawing).unmouseup(stopDrawing).unmousedown(startDrawing);
  targetEl.el = drawObj;
    if (shape.type !== "in-curl") {
    //if (shape.type === "in-line-curve-l" || shape.type === "in-line-curve-r" || shape.type === "in-line") {
    //////clone the el for better cklicking
    targetEl.handle = targetEl.el.clone().attr({
      stroke: "transparent",
      fill: "transparent",
      "stroke-width": 10,
    });
    targetEl.groupedEl = Snap("#stg").g(targetEl.handle, targetEl.el).attr({
      class: "els",
      stroke: "#000000",
    });
    ftAdd(targetEl.groupedEl);
    ui.startM.classList.remove("prop-sel");
    ui.endM.classList.remove("prop-sel");
    targetEl.groupedEl.data("new", 1);
    targetEl.el = targetEl.groupedEl; //need to set this to use in addMrkr
    } else {
    ftAdd(targetEl.el); //add freetransform
    targetEl.el.data("new", 1);
  }
  targetEl.el.data("lineStyle", "solid");
  ui.line[0].classList.add("prop-sel");
  ui.line[1].classList.remove("prop-sel");
  //add drag back to all
  allDrag();
  targetEl.el.freeTransform.showHandles();
}

////////////////////////////////////////////////////////////////////////////


var appendToBuffer = function (pt) {
  buffer.push(pt);
  while (buffer.length > bufferSize) {
          buffer.shift();
  }
  //console.log(buffer);
};

// Calculate the average point, starting at offset in the buffer
var getAveragePoint = function (offset) {
  var len = buffer.length;
  if (len % 2 === 1 || len >= bufferSize) {
          var totalX = 0;
          var totalY = 0;
          var pt, i;
          var count = 0;
          for (i = offset; i < len; i++) {
                  count++;
                  pt = buffer[i];
                  totalX += pt.x;
                  totalY += pt.y;
          }
          return {
                  x: totalX / count,
                  y: totalY / count
          }
  }
  return null;
};

var updateSvgPath = function () {
  var pt = getAveragePoint(0);

  if (pt) {
          // Get the smoothed part of the path that will not change
          strPath += pt.x + " " + pt.y + " ";

          // Get the last part of the path (close to the current mouse position)
          // This part will change if the mouse moves again
          var tmpPath = "";
          for (var offset = 2; offset < buffer.length; offset += 2) {
                  pt = getAveragePoint(offset);
                  tmpPath += pt.x + " " + pt.y + " ";
          }

          

          // Set the complete current path coordinates
          drawObj.attr({ points: strPath + tmpPath});
  }
  //console.log(strPath + tmpPath) ;
};