import targetEl  from "./objects/targetEl.js";
let blurring = null;

/* tinymce config******/
 export default function () {
    return {
      selector: "." + targetEl.mceClass, //'.txt_ed', //'.'+el.node.classList[0], //'.txt_ed',
      auto_focus: "txt_bx", //adds focus when el is clicked
      //document_base_url: 'http://localost:1234/js/tinymce/',
      //theme_url: new URL(`../js/tinymce/themes/silver/theme.js`, import.meta.url),
      //event_root: ".tinymce-body",
      menubar: false,
      inline: true,
      plugins: [
        "link",
        "lists",
        "autolink",
        "advlist",
        "table",
        "autoresize",
        "contextmenu",
        "paste",
        "charmap",
      ],
      toolbar: [
        /*nonbreaking  paste*/
        "undo redo | formatselect fontselect | advlist lists bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignfull lineheight | link numlist bullist outdent indent table charmap",
      ],
      table_toolbar: "",
      table_resize_bars: false,
      table_appearance_options: true,
      //table_column_resizing: 'resizetable',
      //table_sizing_mode: 'relative', //fixed relative responsive auto
      //table_responsive_width: '',  //deprecated, use table_sizing_mode instead
      table_default_attributes: {
        border: "1",
        cellpadding: "5",
        cellspacing: "0",
      },

      //fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 20pt 22pt 24pt 28pt 30pt 32pt 36pt 40pt 48pt',
      lineheight_formats:
        "1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 2 2.3 2.5 2.7 3 3.2 3.4 3.6",
      color_map: [
        "#BFEDD2",
        "Light Green",
        "#FBEEB8",
        "Light Yellow",
        "#F8CAC6",
        "Light Red",
        "#ECCAFA",
        "Light Purple",
        "#C2E0F4",
        "Light Blue",

        "#2DC26B",
        "Green",
        "#F1C40F",
        "Yellow",
        "#E03E2D",
        "Red",
        "#B96AD9",
        "Purple",
        "#3598DB",
        "Blue",

        "#169179",
        "Dark Turquoise",
        "#E67E23",
        "Orange",
        "#BA372A",
        "Dark Red",
        "#843FA1",
        "Dark Purple",
        "#236FA1",
        "Dark Blue",

        "green",
        "Green",
        " #FF5733 ",
        "Orange",
        "red",
        "Red",
        "purple",
        "Purple",
        "blue",
        "Blue",

        "#ECF0F1",
        "Light Gray",
        "#CED4D9",
        "Medium Gray",
        "#95A5A6",
        "Gray",
        "#7E8C8D",
        "Dark Gray",
        "#34495E",
        "Navy Blue",

        "#000000",
        "Black",
        "#ffffff",
        "White",
      ],
      color_cols: 5,
      contextmenu: "copy paste link",
      default_link_target: "_blank",
      link_assume_external_targets: true,
      link_context_toolbar: true,
      font_formats:
        "Arial=arial,helvetica,sans-serif; Brush Script = brush script std medium,brush script std, brush script; Comic Sans MS=comic sans ms,sans-serif; Ink Free = ink free regular, ink free; Mv Boli=mv boli,mv boli regular; Segoe Script=segoe script;  Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;",
      //content_style:  '#txt_bx { font-family: "Arial"; font-size:11px }', //sets default css
      //plugins: ['advlist', 'lists','autoresize'], /*nonbreaking*/
      autoresize_on_init: true,
      //skin: 'oxide-dark',
      //toolbar_persist: true,
      //content_css: 'dark',
      skin: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "oxide-dark"
        : "oxide",
      //content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default'),
      //remove_trailing_brs: false,
      //force_br_newlines : true,
      //force_p_newlines : true,
      //forced_root_block : true,
      //remove_linebreaks : false,
      toolbar_sticky: true,
      lineheight: { inline: "span" },
      valid_elements:
        "h1[style],h2[style],h3[style],#p[style|br],strong,em,span[style],a[href],a[href|target=_blank],ul,ol,li,br,div[style],table[style|border|cellpadding|cellspacing],tr[style],#th[style],thead[style],#td,tfoot[style],br",
      //invalid_elements : 'width',
      valid_styles: {
        "*": "font-size,font-family,color,text-decoration,text-align,line-height,background-color,target",
      },
      //invalid_styles: 'width',
      //block_formats: 'Font Siz=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Text 1=h5; Text 2=format_1; Text3=format_1',
      //block_formats: 'Font Size=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Text 1=h5; Text 2=h6; Text 3=format_1; Text 4=format_1,',
      block_formats:
        "Font Size=p;Tile H1=format_1;Title H2=format_2;Title H3=format_3;Title H4=format_4;Text 1=format_5;Text 2=format_6;Text 3=format_7;Text 4=format_8;Text 5=format_9;",

      formats: {
        format_1: { inline: "span", styles: { "font-size": "48px" } },
        format_2: { inline: "span", styles: { "font-size": "30px" } },
        format_3: { inline: "span", styles: { "font-size": "25px" } },
        format_4: { inline: "span", styles: { "font-size": "20px" } },
        format_5: { inline: "span", styles: { "font-size": "15px" } },
        format_6: { inline: "span", styles: { "font-size": "12px" } },
        format_7: { inline: "span", styles: { "font-size": "11px" } },
        format_8: { inline: "span", styles: { "font-size": "10px" } },
        format_9: { inline: "span", styles: { "font-size": "9px" } },
      },

      // The following option is used to append style formats rather than overwrite the default style formats.
      style_formats_merge: true,

      style_formats_autohide: false,

      paste_auto_cleanup_on_paste: true,
      paste_preprocess: function (editor, args) {
        //document.documentElement.style.overflow = 'hidden';
        //const ors = args.content;
        //args.content = ors.replace(/(<([^>]+)>)/gi, "");
        scrollTo(targetEl.scrollPastepre.x, targetEl.scrollPastepre.y)
        
      },
      paste_postprocess: function(editor, args) {
        console.log(targetEl.scrollPastepre.x);
        console.log(targetEl.scrollPastepre.y);
        scrollTo(targetEl.scrollPastepre.x, targetEl.scrollPastepre.y)
      },
      paste_as_text: true, //removes formatting
      powerpaste_word_import: "clean",
      powerpaste_html_import: "prompt",
      init_instance_callback: function (editor) {
        editor.on("focus", function (e) {
          //set x y any cor-ords for pasting issue
          targetEl.scrollPastepre.x = window.scrollX;
          targetEl.scrollPastepre.y = window.scrollY;
          console.log(window.scrollX);
        console.log(window.scrollY);
          // Applying the specified format
          targetEl.mceStatus = true;
          targetEl.mceObj = editor;
          targetEl.node = editor.bodyElement;
          //change cursor to text and allos selection
          targetEl.node.classList.add("txt_bx_auto");
          //set foreignobject to 100% width and 100% height while editing
          targetEl.foNode.classList.add("act");
        });
        blurring = function (e) {
          //console.log(targetEl);
          targetEl.mceStatus = false;
          //get size of div and set its parent foreignobject below
          let a = editor.bodyElement;
          tinymce.remove(editor);
          //set size of foreigh object
          targetEl.foNode.style.width = a.offsetWidth + 10 + "px";
          targetEl.foNode.style.height = a.offsetHeight + 10 + "px";
          /*add drag back to txt el
               loop over all els to check which is missing the freetransform mousedown event.
               all els must have 4 events attached so if .length = 3 then we add back the event
             */
          let allG = Snap("#stg").selectAll(".els");
          allG.forEach(function (el) {
            if (el.events.length == 2) {
              Snap("#stg").freeTransform(el);
              el.freeTransform.hideHandles({ undrag: false });
              console.log("blur tiny mce - add back event");
            }
          });
          if (targetEl.el) targetEl.el.data("ftStatus", 0);
          //prevent deafault cursor and selection of text
          targetEl.node.classList.remove("txt_bx_auto");
          //remove class .act from foreignobject
          targetEl.foNode.classList.remove("act");
          targetEl.foNode.classList.remove("txt_outlined");
          //el.data("textActive", 0);
          console.log("blurring");
        };
        editor.on("blur", blurring);
      },
    };
  };

  /***************/