import ftAdd from './ftAdd.js';
//import SVGImage from "../modules/objects/stage.js";
import targetEl from "./objects/targetEl.js";
///////////////////paste image or txt from clipboard ctrl v//////////////////////
document.onpaste = function (pasteEvent) {
  // consider the first item (can be easily extended for multiple items)
  const item = pasteEvent.clipboardData;

  if (item.types.length > 0) {
    if (item.items[0].type.indexOf("image") === 0) {
      var file = item.items[0].getAsFile();

      console.log(file);

      const reader = new FileReader();

      if (file.size > 4194304) {
        alert("File is too big! Max file size allowed is 4MB");
        return;
      }

      // convert image file to base64 stringF
      reader.addEventListener(
        "load",
        function () {
          //get image dimensions and add to stage
          const image = new Image();
          image.src = reader.result;
          image.onload = function () {
            let bf = document.querySelector("foreignObject") || null; //bf = before
            var el;
            if (bf != null) {
              el = Snap("#stg").image(
                reader.result,
                window.scrollX + (window.innerWidth / 2 - image.width / 2),
                window.scrollY + (window.innerHeight / 2 - image.height / 2),
                image.width,
                image.height
              )
                .attr({ class: "els" })
                .insertBefore(bf);
            } else {
              el = Snap("#stg").image(
                reader.result,
                window.scrollX + (window.innerWidth / 2 - image.width / 2),
                window.scrollY + (window.innerHeight / 2 - image.height / 2),
                image.width,
                image.height
              ).attr({ class: "els" });
            }
            ftAdd(el);
          };
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (item.items[0].type.indexOf("text") === 0) {
      ////console.log(item.getData('text'));
      if (targetEl.mceStatus === false) {
        alert(
          "Your clipboard contains text. First create a text block first then paste(Ctrl+V) into the text block"
        );
      }
    }
  }
};
/***************/