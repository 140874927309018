import obj from './objects/uiProps.js';

const arr = ['scale(0.25)','scale(0.5)','scale(0.75)','scale(1.00)'];


const stage = document.getElementById("stg");

document.getElementById("zPlus").addEventListener("mousedown", function (e) {
    if(obj.zoom < 3){
        obj.zoom ++;
        document.getElementById("stg").style.transform = arr[obj.zoom];
    } 
    console.log(obj.zoom);
});

document.getElementById("zMinus").addEventListener("mousedown", function (e) {
    if(obj.zoom > 0){
        obj.zoom --
        document.getElementById("stg").style.transform = arr[obj.zoom];
    } 
    console.log(obj.zoom);
});

