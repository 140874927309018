import { stageCenter } from '../modules/stageCenter.js';
//import  SVGImage from '../modules/objects/stage'; //this is creating error becuase of reassign on line 35
import { activeRemove } from '../modules/activeRemove.js';
import ftAdd from '../modules/ftAdd.js';
import targetEl from './objects/targetEl.js';
import { modalStatus} from '../modules/modal.js';
import waitingGif from '../modules/waitingGif.js';
/*************************SVG upload***********************/
   const uploadModal = document.getElementById("modal-svg-import");
  //A: DRAG & DROP
  document.getElementById("dz_svg").addEventListener("dragover", function (e) {
    uploadModal.getElementsByClassName("has-advanced-upload")[0].style.outline = "2px dashed rgb(178, 222, 39)";
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
  });   

  document.getElementById("dz_svg").addEventListener("dragleave", function (e) {
    uploadModal.getElementsByClassName("has-advanced-upload")[0].style.outline = "2px dashed #92b0b3";
  });

  document.getElementById("dz_svg").addEventListener("drop", function (e) {
    e.preventDefault();
    modalStatus('modalImportSVG', 'none', 'block');
    waitingGif('block');
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      if (e.dataTransfer.items[0].kind === "file") {
        Snap("#stg").clear();
        Snap("#stg").remove();
        const reader = new FileReader();
        var file = e.dataTransfer.items[0].getAsFile();
        if (file) {
          reader.readAsDataURL(file);
        }
        reader.addEventListener("load", function () {
          targetEl.SVGUploadFlag = 1;
          Snap.load(reader.result, function (frag) {
            const stageContainer = Snap("#svg-container").append(frag); //stage is <svg
            stageCenter();
            //add outlineremove listener for stage
            Snap("#stg").node.addEventListener("click", activeRemove);
            //attach snap obj to all elements
            let allG = Snap("#stg").selectAll(".els");
            allG.forEach(function (el) {
              ftAdd(el);
              if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
              waitingGif('none');
              modalStatus('', 'none', 'none');
            });
          }); /********END load */
        });
      }
    }
  });

  //B: BUTTON
  //upload buttonftAdd
  document.getElementById("upl_svg").addEventListener("change", function (e) {
    console.log('uploading');
    modalStatus('', '', 'block');
    waitingGif('block');
    //first clear the svg
    Snap("#stg").clear();
    Snap("#stg").remove();
    var fReader = new FileReader();
    fReader.readAsDataURL(document.getElementById("upl_svg").files[0]);
    fReader.onloadend = function (e) {
      //console.log(e.target.result);
      Snap.load(e.target.result, function (frag) {
        const stageContainer = Snap("#svg-container").append(frag); //stage is <svg
        //add event lsitener for when stage is clicked
        Snap("#stg").node.addEventListener("click", activeRemove);
        //attach snap obj to all elements
        let allG = Snap("#stg").selectAll(".els");
        allG.forEach(function (el) {
          ftAdd(el);
          if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false });
        });
        //center stage
        stageCenter();
        modalStatus('', '', 'none');
        waitingGif('none');
      }); /********END load */
    }; 
  });