import targetEl from "./objects/targetEl.js";
import borderProperties from "./borderProperties.js";

export default document.addEventListener("keydown", function (e) {
  switch (e.code) {
    case "Backspace":
      if (targetEl.el != null) {
        if (targetEl.mceStatus == false) {
          if (confirm("Delete Content!")) {
            if (!Object.hasOwn(targetEl.el, "freeTransform")) {
              console.log(targetEl.groupedEl);
              targetEl.groupedEl.freeTransform.unplug();
              targetEl.groupedEl.remove();
            } else {
              targetEl.el.freeTransform.unplug();
              targetEl.node.remove();
            }
            //clear targetEl obj
            targetEl.el = targetEl.node = null;
            //el.data('new',0);
            //show/hide border properties
            borderProperties(targetEl.el);
          }
        }
      } else {
        if (targetEl.mceStatus === false) {
          alert("No item selected!");
        }
      }
      break;
  }
});
/*end keypress********************************/