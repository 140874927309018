//import SVGImage from "../modules/objects/stage.js";
/* promp before navigating away *********************************/
addEventListener("beforeunload", (event) => {
  event.preventDefault();
  // Google Chrome requires returnValue to be set.
  event.returnValue = "";
  if (storageAvailable("localStorage")) {
    const serializer = new XMLSerializer();
    const els = Snap("#stg").node.lastChild.childNodes;
    for (const el of els) {
      if (el.classList[0] === "fthandle") {
        el.parentNode.remove();
        return;
      }
    }
    const str = serializer.serializeToString(Snap("#stg").node);
    //set the storage item
    sessionStorage.setItem("svglocal", str);
    console.log("stored!");
  }
});
/***********************************/

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}