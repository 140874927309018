 /* toggle full screen *********************/
 document.getElementById("ut-fs").addEventListener("click", function toggleFullScreen() {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
});
/***********************************/