//import SVGImage from "./objects/stage";
import textNode from "../modules/textNodeDraw.js";
import {startDrawing} from "../modules/drawing.js";
import targetEl from "./objects/targetEl.js";
import ftAdd from './ftAdd.js';

/* clone tool*********/
export default document.getElementById("c").addEventListener("click", function () {
  //remove existing events first
  Snap("#stg").unmousedown(startDrawing).unmousedown(textNode);
  Snap("#stg").node.style.cursor = "default";
  //check if targetEl.el is not null i.e is an element selected
  if (targetEl.el === null) {
    alert("No element selected! Select an element to copy");
  } else {
    let newObjX = null;
    if (
      targetEl.name == "rect" ||
      targetEl.name == "image" ||
      targetEl.node.classList[1] === "txt" ||
      targetEl.name == "ellipse" ||
      targetEl.name == "line"
    ) {
      const newObj = targetEl.el.clone();
      if (newObj.node.classList[1] === "txt") {
        var newNode = newObj.node.children[0];
        newNode.classList.replace(
          newNode.classList[1],
          "txt_ed" + Math.floor(Math.random() * 100000000)
        );
        newNode.id = "txt_bx"; //this is for focus
      }
      //const newObjNN = newObj.node.nodeName;
      if (
        targetEl.name == "rect" ||
        targetEl.name == "image" ||
        targetEl.node.classList[1] === "txt"
      ) {
        //this is the original node name
        //need to add the ftAdd() to the new object after editing position so save the obj to a new var
        newObjX = newObj.attr({
          x: "+=50",
          y: "+=50",
        });
      } else if (targetEl.name == "ellipse") {
        console.log(newObj);
        newObjX = newObj.attr({
          cx: "+=50",
          cy: "+=50",
        });
      } else if (targetEl.name == "line") {
        //const el = newObj.node.childNodes;
        console.log(newObj.children());
        newObj.children().forEach((el) => {
          console.log(el);
          newObjX = el.attr({
            x1: "+=50",
            x2: "+=50",
            y1: "+=50",
            y2: "+=50",
          });
        });
        newObjX = newObj;
      }
      ftAdd(newObjX);
    } else {
      console.log(targetEl.name);
      alert("Copy/Paste failed!");
    }
  }
});