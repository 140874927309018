//import SVGImage from "./objects/stage";
  
  //add drag to all els
  export const allDrag = () => {
    const allG = Snap("#stg").selectAll(".els");
    allG.forEach((el) => {
      if (el.freeTransform && el.type /*!== "foreignObject"*/)   el.freeTransform.showHandles().hideHandles({ undrag: false });
    });
  };

  //remove drag from all els
  export const allUndrag = () => {
    let allG = Snap("#stg").selectAll(".els");
      allG.forEach((el) => {
        if (el.freeTransform /*&& el.type !== "foreignObject"*/)
          el.freeTransform.hideHandles({ undrag: true });
      });
  };