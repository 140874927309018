//state object
import targetEl from "./objects/targetEl.js";
import ui from './objects/uiProps.js';
import borderProperties from "../modules/borderProperties.js";
import blurring from "../modules/configTinymce.js";
import {mathObjects, shapeEllipse, shapeRectangular, shapePath, shapeImage} from './resetMatrix.js';




 /*remove textbox outline and active handles on other els*********************/
export  function activeRemove (e) {
  const el = targetEl.mathConst;

  if(e.target.classList.contains("act")) el.node.classList.remove("act");
  

  const allG = Snap("#stg").selectAll(".els");
  e.stopPropagation(); //stop bubbling to stage
  //////if stage is clicked
  if (e.target.id === "stg") {
    allG.forEach(function (el) {
      //remove outline on txt
      if ((el.data("txtOutline") == 1 && el.data("new") == 0) || el.data("new") == null) {
        el.node.classList.remove("txt_outlined");
        el.data("txtOutline", 0);
        targetEl.textMode = false;
      }
      //remove drag handels if active on an element
      if (el.data("ftStatus") === 1) {
        if (el.data("new") == 0 || el.data("new") == null) {
          //remove from all el except el that was just drawn
          if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false }); //Object { node: rect#s.els, paper: {…}, type: "rect", id: "rectSl2k5fo5o6", anims: {}, _: {…}, realPath: (5) […], freeTransform: {…}, _drag: {}, events: (4) […] }
          el.data("ftStatus", 0);
          //clear targetEl obj
          targetEl.el = targetEl.node = null;
          el.data("new", 0);
          //show/hide border properties
          borderProperties(targetEl.el);
        } else {
          //show/hide border properties
          borderProperties(targetEl.el);
          el.data("new", 0);
        }
      }
      //reset new to false on any new element
      if (el.data("new") == 1) el.data("new", 0);
    });

    //hide context menu if stage clicked
    if (ui.cMenu.opacity === 1) {
      ui.cMenu.left = "0px";
      ui.cMenu.top = "0px";
      ui.cMenu.opacity = 0;
      ui.cMenu.display = "none";
    }
  } else {
    //this handles: if another el is clicked remove handles
    //in freetransform.js line 56 / 555 function sets Element.dragEnd to true when resize end. then we reset here to false
    Snap.plugin(function (Snap, Element, Paper, global, Fragment) {
      //if element has resize handles and is being resized then dont remove handles
      if (Element.dragEnd) {
        Element.dragEnd = false;
      } else {
        //if another el is clicked remove handles
        allG.forEach(function (el) {
          if (el.data("txtOutline") === 1) {
            el.node.classList.remove("txt_outlined");
            el.data("txtOutline", 0);
            //remove pe class from images
            //el.node.classList.remove("pe");
          }
          // remove handles/outline from active elements
          if (el.data("ftStatus") === 1) {
            if(el.freeTransform) el.freeTransform.hideHandles({ undrag: false }); //Object { node: rect#s.els, paper: {…}, type: "rect", id: "rectSl2k5fo5o6", anims: {}, _: {…}, realPath: (5) […], freeTransform: {…}, _drag: {}, events: (4) […] }
            el.data("ftStatus", 0);
          }
          //if el is text box then blur
          if (
            targetEl.mceStatus == true &&
            e.target.closest(".mce-edit-focus") != null
          ) {
            //do nothing becuase this is active text el
          } else if (targetEl.mceStatus == true) {
            //blur editor if clicked element is not stage but another txt el
            blurring();
          }
        });
      }
    });
  }

  if (
      /*targetEl.el !== null &&*/ el.node &&
      //el.node.nodeName !== "image" &&
      el.node.classList[1] !== "txt" &&
      Object.hasOwn(el, "matrix") &&
      !mathObjects(el.matrix, {a: 1,b: 0,c: 0,d: 1,e: 0,f: 0,})
    ) {
    /*
      issue: with 'line', when clicking on 'line' there is a difference btw the object for import and a new object.
       imported object: Object { node: g.els, paper: {…}, type: "g", id: "gSlf59o7vlk5", anims: {}, _: {…}, add: r(a), el: el(a, b), rect: rect(a, b, c, d, e, f), circle: circle(a, b, c), … }
       new object: Object { 0: {…}, 1: {…}, node: g.els, paper: {…}, type: "g", id: "gSlf59o7vltb", anims: {}, _: {…}, add: r(a), el: el(a, b), … }
      will have to work around this - for imported object the click 'shape' var is 'g' and the new object is 'line'
    */
    const shape = el.children()[1] ? el.children()[1].type : el.type;
    let { a, b, c, d, e, f } = el.matrix;
    console.log(el);
    console.log(el[1]);
    console.log(shape);
    switch (shape) {
      case "ellipse":
        const { cx, cy, rx, ry } = el.attr();
          shapeEllipse(el, a, b, c, d, e, f, cx, cy, rx, ry);
        break;
      case "rect":
        shapeRectangular(el, a, b, c, d, e, f);
      break;
      case "image":
        shapeImage(el, a, b, c, d, e, f);
      break;
      case "polyline":
        //still do polyline
      break;
      default: //shape = path
      shapePath(shape, el, a, b, c, d, e, f);
        
    }
  }
};

//remove outline/habdles on text if stage is clicked
Snap(".stg").node.addEventListener("click", activeRemove);