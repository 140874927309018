export const mathReset = (el) => {
  const obj = { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 }
  el.matrix = obj;
  el.transform().diffMatrix 
  = el.transform().globalMatrixel 
  = el.transform().localMatrixtotalMatrix 
  = obj;
  el.node.attributes.transform.nodeValue = "matrix(1,0,0,1,0,0)";
};

export const mathObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

export const shapeEllipse = (el, a, b, c, d, e, f) => {
  console.log(el);
  const elChild = el.children();
  const { cx, cy, rx, ry } = elChild[1].attr();
  const newcx = a * cx + c * cy + e,
        newcy = b * cx + d * cy + f,
        tempx1 = a * (cx - rx) + c * cy + e,
        tempy1 = b * (cx - rx) + d * cy + f,
        tempx2 = a * cx + c * (cy - ry) + e,
        tempy2 = b * cx + d * (cy - ry) + f,
        newrx = Math.sqrt(
          (newcx - tempx1) * (newcx - tempx1) +
            (newcy - tempy1) * (newcy - tempy1)
        ),
        newry = Math.sqrt(
          (newcx - tempx2) * (newcx - tempx2) +
            (newcy - tempy2) * (newcy - tempy2)
        ),
        [rotA, rotX, rotY] = [
          (Math.atan((tempy1 - newcy) / (tempx1 - newcx)) * 180) / 3.1415,
          newcx,
          newcy,
        ];
      mathReset(el);
      elChild[0].attr({ cx: newcx, cy: newcy, rx: newrx, ry: newry });
      elChild[1].attr({ cx: newcx, cy: newcy, rx: newrx, ry: newry });
      el.node.attributes.transform.nodeValue = `matrix(1,0,0,1,0,0) rotate(${rotA}, ${rotX}, ${rotY})`;
      el.freeTransform.unplug();
      Snap("#stg").freeTransform(el, {
        size: 3,
        rotate: true,
        keepRatio: false,
        attrs: { fill: "#ffffff", stroke: "#000000" },
      });
      el.freeTransform.showHandles();
      el.data("ftStatus", 1);
}

export const shapeRectangular = ( el, a, b, c, d, e, f) => {
  const elChild = el.children();
  console.log(el);
  console.log(elChild);
  const { x, y, width, height } =  elChild[1].attr(),
          new1x = a * x + c * y + e,
          new1y = b * x + d * y + f,
          new2x = a * x + a * width + c * y + e,
          new2y = b * x + b * width + d * y + f,
          new3x = a * x + c * y + c * height + e,
          new3y = b * x + d * y + d * height + f,
          newwidth = Math.sqrt(
            (new1x - new2x) * (new1x - new2x) +
              (new1y - new2y) * (new1y - new2y)
          ),
          newheight = Math.sqrt(
            (new1x - new3x) * (new1x - new3x) +
              (new1y - new3y) * (new1y - new3y)
          ),
          [rotAa, rotXx, rotYy] = [
            (Math.atan((new2y - new1y) / (new2x - new1x)) * 180) / 3.1415,
            new1x,
            new1y,
          ];
        mathReset(el);
        //el.attr({x:new1x,y:new1y,width:newwidth,height:newheight, transform: `rotate(${rotAa}, ${rotXx}, ${rotYy})`});
        elChild[1].attr({
          x: new1x,
          y: new1y,
          width: newwidth,
          height: newheight,
        });
        elChild[0].attr({
          x: new1x,
          y: new1y,
          width: newwidth,
          height: newheight,
        });
        el.node.attributes.transform.nodeValue = `matrix(1,0,0,1,0,0) rotate(${rotAa}, ${rotXx}, ${rotYy})`;
        el.freeTransform.unplug();
        Snap("#stg").freeTransform(el, {
          size: 3,
          rotate: true,
          keepRatio: false,
          attrs: { fill: "#ffffff", stroke: "#000000" },
        });
        el.freeTransform.showHandles();
        el.data("ftStatus", 1);
}


export const shapeImage = ( el, a, b, c, d, e, f) => {
  const { x, y, width, height } = el.attr(),
          new1x = a * x + c * y + e,
          new1y = b * x + d * y + f,
          new2x = a * x + a * width + c * y + e,
          new2y = b * x + b * width + d * y + f,
          new3x = a * x + c * y + c * height + e,
          new3y = b * x + d * y + d * height + f,
          newwidth = Math.sqrt(
            (new1x - new2x) * (new1x - new2x) +
              (new1y - new2y) * (new1y - new2y)
          ),
          newheight = Math.sqrt(
            (new1x - new3x) * (new1x - new3x) +
              (new1y - new3y) * (new1y - new3y)
          ),
          [rotAa, rotXx, rotYy] = [
            (Math.atan((new2y - new1y) / (new2x - new1x)) * 180) / 3.1415,
            new1x,
            new1y,
          ];
        mathReset(el);
        //el.attr({x:new1x,y:new1y,width:newwidth,height:newheight, transform: `rotate(${rotAa}, ${rotXx}, ${rotYy})`});
        el.attr({
          x: new1x,
          y: new1y,
          width: newwidth,
          height: newheight,
        });
        el.node.attributes.transform.nodeValue = `matrix(1,0,0,1,0,0) rotate(${rotAa}, ${rotXx}, ${rotYy})`;
        el.freeTransform.unplug();
        Snap("#stg").freeTransform(el, {
          size: 3,
          rotate: true,
          keepRatio: false,
          attrs: { fill: "#ffffff", stroke: "#000000" },
        });
        el.freeTransform.showHandles();
        el.data("ftStatus", 1);
}





export const shapePath = (shape, el, a, b, c, d, e, f) => {
  const elChild = el.children();
  console.log(el);
  console.log(elChild);
  if(elChild[1] && (shape === 'line' || elChild[1].node.nodeName === 'line')){
   //line or g>line
 let { x1, x2, y1, y2 } = elChild[1].attr();
 let newx1 = a * x1 + c * y1 + e,
   newy1 = b * x1 + d * y1 + f,
   newx2 = a * x2 + c * y2 + e,
   newy2 = b * x2 + d * y2 + f;
   elChild[1].attr({
   x1: newx1,
   x2: newx2,
   y1: newy1,
   y2: newy2,
 });
 elChild[0].attr({
   x1: newx1,
   x2: newx2,
   y1: newy1,
   y2: newy2,
 });
 mathReset(el);
 el.freeTransform.unplug();
 Snap("#stg").freeTransform(el, {
   size: 3,
   rotate: true,
   keepRatio: false,
   attrs: { fill: "#ffffff", stroke: "#000000" },
 });
 el.freeTransform.showHandles();
 el.data("ftStatus", 1);
 }else if(elChild[1]){
   //path or g>path
   console.log(elChild[1].node.attributes);
   var [mx, my, s_str, qx1, qy1, qx2, qy2] =
   elChild[1].node.attributes.d.value.split(" "),
   f_str = mx.substr(0, 1),
   mx = mx.substr(1),
   //console.log(f_str,mx,my,s_str,qx1,qy1,qx2,qy2);
   new_mx = a * mx + c * my + e,
   new_my = b * mx + d * my + f,
   new_qx1 = 0,
   new_qy1 = 0,
   new_qx2 = 0,
   new_qy2 = 0;
 if (s_str == "Q") {
   ////  Q - Capital letters means absolutely positioned
   new_qx1 = a * qx1 + c * qy1 + e;
   new_qy1 = b * qx1 + d * qy1 + f;
   new_qx2 = a * qx2 + c * qy2 + e;
   new_qy2 = b * qx2 + d * qy2 + f;
 } else {
   ////// q- lower cases means relatively positioned.
   new_qx1 = a * qx1 + c * qy1;
   new_qy1 = b * qx1 + d * qy1;
   new_qx2 = a * qx2 + c * qy2;
   new_qy2 = b * qx2 + d * qy2;
 }
 elChild[0].node.attributes.d.value = `${f_str}${new_mx} ${new_my} ${s_str} ${new_qx1} ${new_qy1} ${new_qx2} ${new_qy2}`;
 elChild[1].node.attributes.d.value = `${f_str}${new_mx} ${new_my} ${s_str} ${new_qx1} ${new_qy1} ${new_qx2} ${new_qy2}`;
 mathReset(el);
 el.freeTransform.unplug();
 Snap("#stg").freeTransform(el, {
   size: 3,
   rotate: true,
   keepRatio: false,
   attrs: { fill: "#ffffff", stroke: "#000000" },
 });
 el.freeTransform.showHandles();
 el.data("ftStatus", 1);
 }
 else{
   console.log('STILL DO: curly bracket resize handles rewrite to matrix');
   console.log(el);
 }
}