import targetEl  from "../objects/targetEl.js";
import shape from "../objects/shapes.js";
import uiProps from "../objects/uiProps.js";
import {addMrkr, removeMrkr} from "../ui/marker.js";

/*G: bottom bar***********************/

  //color swatches
  document.getElementById("swatches").addEventListener("click", function (e) {
    let color = e.target.dataset.elColor;

    if (targetEl.el != null) {
      //fill attr for shapes
      if (
        shape.color === "fill" &&
        (targetEl.name === "circle" || targetEl.name === "rect" || targetEl.name === "ellipse")) {
        targetEl.el.attr({ fill: color });
      } else if (targetEl.name === "polyline") {
        targetEl.el.attr({ stroke: color });
        //stroke attr
      } else if (targetEl.name === "circle" || targetEl.name === "rect" || targetEl.name === "ellipse") {
        targetEl.el.attr({ stroke: color });
      } else if (targetEl.name === "path" || targetEl.name === "line") {
        //this sets the el for first draw or clicked on
        let el = targetEl.el.node.childNodes[1] || targetEl.el.node; //clicked on || drawing
        el.attributes.stroke.value = color;
        const marker = el.style;

        //marker end
        if (marker.markerEnd) {
          let markerId = marker.markerEnd.slice(6, -2);
          document
            .getElementById(markerId)
            .firstChild.setAttribute("fill", color);
        }
        //marker start
        if (marker.markerStart) {
          let markerId = marker.markerStart.slice(6, -2);
          document
            .getElementById(markerId)
            .firstChild.setAttribute("fill", color);
        }
      }
    } else if (targetEl.el === null) {
      alert("No item selected.");
    }
  });


  

  /* property checkboxes*/
  document.getElementById("props").addEventListener("click", function (e) {
    if (targetEl.el === null) {
      alert("select el");
    } else {
      let box = e.target,
        boxId = box.id,
        elLine = targetEl.el[1] || targetEl.el, // clicked on || drawing
        elShape = targetEl.el,
        line = uiProps.line,
        color = uiProps.color,
        name = targetEl.name;
      if (boxId) {
        switch (boxId) {
          case "start":
            if (name === "line" || name === "path" || name === "polyline") {
              box.classList.toggle("prop-sel")
                ? addMrkr(elLine, boxId)
                : removeMrkr(elLine, boxId);
            }
            break;
          case "end":
            if (name === "line" || name === "path" || name === "polyline") {
              box.classList.toggle("prop-sel")
                ? addMrkr(elLine, boxId)
                : removeMrkr(elLine, boxId);
            }
            break;
          case "dashed":
            name === "line"
              ? elLine.attr({ "stroke-dasharray": "5 4" })
              : elShape.attr({ "stroke-dasharray": "5 4" });
            targetEl.el.data("lineStyle", "dashed");
            line[1].classList.add("prop-sel");
            line[0].classList.remove("prop-sel");
            console.log(targetEl.el.data());
            break;
          case "solid":
            name === "line"
              ? elLine.attr({ "stroke-dasharray": "0 0" })
              : elShape.attr({ "stroke-dasharray": "0 0" });
            targetEl.el.data("lineStyle", "solid");
            line[0].classList.add("prop-sel");
            line[1].classList.remove("prop-sel");
            console.log(targetEl.el.data());
            break;
          case "fill":
            shape.color = "fill";
            color[1].classList.add("prop-sel");
            color[0].classList.remove("prop-sel");
            break;
          case "stroke":
            shape.color = "stroke";
            color[0].classList.add("prop-sel");
            color[1].classList.remove("prop-sel");
            break;
        }
      }
    }
  });